// Esse arquivo possui todos os métodos globais da aplicação
import { settings } from "@/config";
export default {
  data() { },
  created: function () { },
  methods: {
    retornaSessao: function (sessao) {
      var objSessao = JSON.parse(sessionStorage.getItem(sessao));
      if (objSessao instanceof Array) {
        return objSessao[0];
      } else {
        return objSessao;
      }
    },
    getFastSessao(sessao) {
      var objSessao = JSON.parse(sessionStorage.getItem(sessao));
      if (objSessao instanceof Array) {
        return objSessao;
      } else {
        return objSessao;
      }
    },
    setFastSessao(sessao, data) {
      sessionStorage.setItem(sessao, JSON.stringify(data));
    },
    // Verifica se usuário possui token e informações pessoais
    validaSessao() {
      console.log("Validando sessão inicial...");
      if (this.retornaSessao(settings.fastSessaoUsuario) != null && this.retornaSessao(settings.fastSessaoToken) != null) {
        //console.log("Usuário logado...");
        this.$store.state.fastUsuario = this.retornaSessao(settings.fastSessaoUsuario);
        return true;
      } else {
        //console.log("Usuário não logado...");
        return false;
      }
    },
    // Retorna apenas a string do token
    retornaToken() {
      const objToken = JSON.parse(sessionStorage.getItem(settings.fastSessaoToken));
      if (objToken) {
        return objToken.access_token;
      } else {
        return false;
      }
    },
    retornaRefreshToken() {
      const objToken = JSON.parse(sessionStorage.getItem(settings.fastSessaoToken));
      if (objToken) {
        return objToken.refresh_token;
      } else {
        return false;
      }
    },
    // Opções da requisição AJAX (GET ou POST)
    fastAjaxOptions: function (method, param = "") {
      let options = {};
      if (method == "POST") {
        options = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + this.retornaToken(),
          },
          method: method,
          body: param,
        };
      } else {
        options = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + this.retornaToken(),
          },
          method: "GET",
        };
      }
      return options;
    },
    fastAjaxOptionsHtml: function (method, param = "") {
      let options = {};
      if (method == "POST") {
        options = {
          headers: {
            "Content-Type": "text/plain; charset=utf-8",
            Authorization: "Bearer " + this.retornaToken(),
          },
          method: method,
          body: param,
        };
      } else {
        options = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + this.retornaToken(),
          },
          method: "GET",
        };
      }
      return options;
    },
    // Limpa toda a sessão
    fastLogout() {
      sessionStorage.clear();
    },
    // Captura parâmetros na URL
    getUrlParameter(sParam) {
      let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
      }
    },
    // Exibe modal genérico (plugin do VUE)
    showModal(id, idHide = null) {
      if (idHide) this.$modal.hide(idHide);
      this.$modal.show(id);
    },
    // Exibe modal bootstrap (plugin do VUE)
    showModalBootstrap(id, idHide = null) {
      if (idHide) this.$bvModal.hide(idHide);
      this.$bvModal.show(id);
    },
    // Esconde modal genérico (plugin do VUE)
    hideModal(id) {
      this.$modal.hide(id);
    },
    // Esconde modal bootstrap (plugin do VUE)
    hideModalBootstrap(id) {
      this.$bvModal.hide(id);
    },
    // Modal com botões (ainda não utilizado mas deve se tornar o modal padrão de confirmação de ações)
    fastModalDialog(directive, title, text, confirmTitle, confirmFunc, cancelTitle) {
      if (confirmTitle && cancelTitle) {
        this.$modal.show(directive, {
          title: title,
          text: text,
          buttons: [
            {
              title: confirmTitle,
              handler: () => {
                confirmFunc();
              },
            },
            {
              title: cancelTitle,
              handler: () => {
                this.$modal.hide(directive);
              },
            },
          ],
        });
      } else if (cancelTitle) {
        this.$modal.show(directive, {
          title: title,
          text: text,
        });
      } else {
        this.$modal.show(directive, {
          title: title,
          text: text,
          buttons: [
            {
              title: cancelTitle,
              handler: () => {
                this.$modal.hide(directive);
              },
            },
          ],
        });
      }
    },
    // Valida a cada 60 segundos o token
    async validaToken(endFastEad, endApiFastEad) {
      try {
        let resp = await fetch(endApiFastEad + "api/fast_plataforma_usuario/validaToken", this.fastAjaxOptions("GET"));
        if (resp.status == 401) {
          this.fastLogout();
          window.location.href = "/?login";
          return false;
        }
        let json = await resp.json();
        if (json == "OK") {
          console.log("Token validado");
          setTimeout(() => {
            this.validaToken(endFastEad, endApiFastEad);
          }, 60000);
        } else {
          console.log("Token != OK", json);
          this.fastLogout();
          window.location.href = "/?login";
        }
      } catch (e) {
        console.log("Token Catch", e);
        setTimeout(() => {
          this.validaToken(endFastEad, endApiFastEad);
        }, 60000);
        //this.fastLogout();
        //window.location.href = "/?login";
      }
    },
    // Verifica se é mobile
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    // Busca plataforma
    async buscaAPIPlataforma(id_plataforma) {
      let resp = fetch(settings.endApiFastEad + "api/fast_plataforma/lista_detalhes?id_plataforma=" + id_plataforma, this.fastAjaxOptions("GET"));
      return (await resp).json();
    },
    // Busca permissões da plataforma
    async buscaAPIPlataformaPermissoes(id_plataforma) {
      //let resp = fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/lista_plataformas_usuario?id_plataforma=" + id_plataforma + "&administrador=&professor=", this.fastAjaxOptions("GET"));
      let resp = fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/lista_permissoes_usuario?id_plataforma=" + id_plataforma, this.fastAjaxOptions("GET"));
      return (await resp).json();
    },
    ajustaLinkImageUser(path) {
      if (!path) return "https://toussaint.concurseiro.com/css/images/avatar.svg";
      else if (path.indexOf("facebook.com") != "-1") return path;
      else if (path.indexOf("/ImagensUsuarios/") != "-1") return "https://toussaint.concurseiro.com" + path;
      else return this.ajustaLinkApiAntiga(path);
    },
    ajustaLinkApiAntiga(path) {
      if (path) {
        if (path.indexOf("https://api.fastead.com") != "-1") return path.replace('https://api.fastead.com', 'https://toussaint.concurseiro.com');      
      }      
      return path;
    },
    // Busca personalização da plataforma
    async getFastPersonalizacao(id_plataforma, refresh) {
      return new Promise(async (resolve, reject) => {
        // Se não tiver na sessão, ou forçar refresh for true, ou a plataforma da sessão é diferente da passada por parâmetro
        if (!this.getFastSessao(settings.fastSessaoPlataformaPersonalizacao) || refresh || this.getFastSessao(settings.fastSessaoPlataformaPersonalizacao).id_plataforma != id_plataforma) {
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_personalizacao/lista?id_plataforma=" + id_plataforma, this.fastAjaxOptions("GET"));
            let json = await resp.json();
            let obj = Array.from(json);
            if (obj.length) {
              this.$store.state.fastPersonalizacao.id_plataforma = id_plataforma;

              if (obj[0].aluno_visualiza_meus_cursos == null) obj[0].aluno_visualiza_meus_cursos = true;

              if (obj[0].aluno_visualiza_minhas_lives == null) obj[0].aluno_visualiza_minhas_lives = true;

              if (obj[0].aluno_visualiza_atendimento == null) obj[0].aluno_visualiza_atendimento = true;

              if (obj[0].aluno_visualiza_mural == null) obj[0].aluno_visualiza_mural = true;

              if (obj[0].aluno_visualiza_meus_cadernos == null) obj[0].aluno_visualiza_meus_cadernos = true;

              if (obj[0].aluno_visualiza_minhas_capacitacoes == null) obj[0].aluno_visualiza_minhas_capacitacoes = true;

              if (obj[0].aluno_visualiza_loja_plataforma == null) obj[0].aluno_visualiza_loja_plataforma = true;

              if (obj[0].aluno_visualiza_tutoriais == null) obj[0].aluno_visualiza_tutoriais = true;
              if (obj[0].aluno_visualiza_turmas == null) obj[0].aluno_visualiza_turmas = true;
              if (obj[0].aluno_visualiza_requerimentos == null) obj[0].aluno_visualiza_requerimentos = true;
              if (obj[0].aluno_visualiza_ocorrencias == null) obj[0].aluno_visualiza_ocorrencias = true;
              if (obj[0].aluno_visualiza_perguntas == null) obj[0].aluno_visualiza_perguntas = true;
              if (obj[0].aluno_visualiza_aula_particular == null) obj[0].aluno_visualiza_aula_particular = true;
              if (obj[0].aluno_visualiza_revisao_redacao == null) obj[0].aluno_visualiza_revisao_redacao = true;              

              if (!obj[0].meus_cursos_nome) obj[0].meus_cursos_nome = "Meus cursos";

              if (!obj[0].minhas_lives_nome) obj[0].minhas_lives_nome = "Minhas lives";

              if (!obj[0].meus_cadernos_nome) obj[0].meus_cadernos_nome = "Meus cadernos";

              if (!obj[0].minhas_capacitacoes_nome) obj[0].minhas_capacitacoes_nome = "Minhas capacitações";
              if (!obj[0].minhas_capacitacoes_setor) obj[0].minhas_capacitacoes_setor = "Setores";
              if (!obj[0].minhas_capacitacoes_cargo) obj[0].minhas_capacitacoes_cargo = "Cargos";

              if (!obj[0].loja_plataforma_nome) obj[0].loja_plataforma_nome = "Loja da plataforma";

              if (!obj[0].menu_font_color) obj[0].menu_font_color = "rgb(42, 89, 106)";

              if (!obj[0].menu_background_color) obj[0].menu_background_color = "#fff";

              if (!obj[0].titulo_boas_vindas) obj[0].titulo_boas_vindas = "Bem vindo, [nome_aluno]";

              this.$store.state.fastPersonalizacao = obj[0];
              this.setFastSessao(settings.fastSessaoPlataformaPersonalizacao, this.$store.state.fastPersonalizacao);
              //console.log("this.$store.state.fastPersonalizacao", this.$store.state.fastPersonalizacao);
            }
            resolve(true);
          } catch (e) {
            console.log("Erro", e);
            reject(e);
          }
        } else {
          this.$store.state.fastPersonalizacao = this.getFastSessao(settings.fastSessaoPlataformaPersonalizacao);
          //console.log("this.$store.state.fastPersonalizacao", this.$store.state.fastPersonalizacao);
          resolve(true);
        }
      });
    },
    // Informações e permissões na plataforma
    async getPlataformaData(id_plataforma) {
      return new Promise(async (resolve, reject) => {
        let obj = "";
        // Se dados da plataforma existem na sessão
        if (this.retornaSessao(settings.fastSessaoPlataforma)) {
          obj = this.retornaSessao(settings.fastSessaoPlataforma);
          // Verifica se o id_plataforma da URL é o mesmo da sessão
          if (obj.id_plataforma != id_plataforma) {
            // Busca na API
            this.buscaAPIPlataforma(id_plataforma).then((obj) => {
              if (obj.length > 0) {
                this.$store.state.fastPlataforma = obj[0];
                this.setFastSessao(settings.fastSessaoPlataforma, this.$store.state.fastPlataforma);
                resolve(true);
              } else {
                reject(false);
              }
            });
          } else {
            this.$store.state.fastPlataforma = obj;
            resolve(true);
          }
        } else {
          // Busca na API
          this.buscaAPIPlataforma(id_plataforma).then((obj) => {
            if (obj.length > 0) {
              this.$store.state.fastPlataforma = obj[0];
              this.setFastSessao(settings.fastSessaoPlataforma, this.$store.state.fastPlataforma);
              resolve(true);
            } else {
              reject(false);
            }
          });
        }
      });
    },
    async getPlataformaPermissoesData(id_plataforma) {
      return new Promise(async (resolve, reject) => {
        let obj = "";
        // Se dados da plataforma existem na sessão
        if (this.retornaSessao(settings.fastSessaoPermissoes)) {
          obj = this.retornaSessao(settings.fastSessaoPermissoes);
          if (obj.id_plataforma != id_plataforma) {
            // Busca na API
            this.buscaAPIPlataformaPermissoes(id_plataforma).then((obj) => {
              if (obj.length) {
                obj = JSON.parse(obj)   
                obj.ativo = obj.ativo == "S" ? "S" : "N"
                obj.administrador = obj.administrador == "S" ? "S" : "N"
                obj.professor = obj.professor == "S" ? "S" : "N"
                obj.aluno = obj.aluno == "S" ? "S" : "N"
                obj.secretario = obj.secretario == "S" ? "S" : "N"
                obj.tesouraria = obj.tesouraria == "S" ? "S" : "N"
                obj.responsavel = obj.responsavel == "S" ? "S" : "N"

                obj.edita_cursos = obj.edita_cursos == "S" ? "S" : "N"
                obj.edita_usuarios = obj.edita_usuarios == "S" ? "S" : "N"
                obj.edita_professores = obj.edita_professores == "S" ? "S" : "N"
                obj.edita_plataforma = obj.edita_plataforma == "S" ? "S" : "N"
                obj.edita_matriz = obj.edita_matriz == "S" ? "S" : "N"
                obj.edita_financeiro = obj.edita_financeiro == "S" ? "S" : "N"
                obj.edita_admins = obj.edita_admins == "S" ? "S" : "N"
                obj.edita_alunos = obj.edita_alunos == "S" ? "S" : "N"
                this.$store.state.fastPermissoes = obj
                //this.$store.state.fastPermissoes = obj[0];
                //if (this.$store.state.fastPermissoes.administrador == "N" && this.$store.state.fastPermissoes.professor == "S") this.$store.state.fastPermissoes.professor_only = true;
                this.setFastSessao(settings.fastSessaoPermissoes, this.$store.state.fastPermissoes);
                resolve(true);
              } else {
                reject("Você não possui permissões na plataforma");
              }
            });
          } else {
            this.$store.state.fastPermissoes = obj;
            //if (this.$store.state.fastPermissoes.administrador == "N" && this.$store.state.fastPermissoes.professor == "S") this.$store.state.fastPermissoes.professor_only = true;
            resolve(true);
          }
        } else {
          // Busca na API          
          this.buscaAPIPlataformaPermissoes(id_plataforma).then((obj) => {    
            console.log(obj, obj.length)
            if (obj) {              
              //obj = JSON.parse(obj) 
              obj.ativo = obj.ativo == "S" ? "S" : "N"
              obj.administrador = obj.administrador == "S" ? "S" : "N"
              obj.professor = obj.professor == "S" ? "S" : "N"
              obj.aluno = obj.aluno == "S" ? "S" : "N"
              obj.secretario = obj.secretario == "S" ? "S" : "N"
              obj.tesouraria = obj.tesouraria == "S" ? "S" : "N"
              obj.responsavel = obj.responsavel == "S" ? "S" : "N"

              obj.edita_cursos = obj.edita_cursos == "S" ? "S" : "N"
              obj.edita_usuarios = obj.edita_usuarios == "S" ? "S" : "N"
              obj.edita_professores = obj.edita_professores == "S" ? "S" : "N"
              obj.edita_plataforma = obj.edita_plataforma == "S" ? "S" : "N"
              obj.edita_matriz = obj.edita_matriz == "S" ? "S" : "N"
              obj.edita_financeiro = obj.edita_financeiro == "S" ? "S" : "N"
              obj.edita_admins = obj.edita_admins == "S" ? "S" : "N"
              obj.edita_alunos = obj.edita_alunos == "S" ? "S" : "N"
              this.$store.state.fastPermissoes = obj
              //this.$store.state.fastPermissoes = obj[0];
              //if (this.$store.state.fastPermissoes.administrador == "N" && this.$store.state.fastPermissoes.professor == "S") this.$store.state.fastPermissoes.professor_only = true;
              this.setFastSessao(settings.fastSessaoPermissoes, this.$store.state.fastPermissoes);
              resolve(true);
            } else {
              reject("Você não possui permissões na plataforma");
            }
          });
        }
      });
    },
    // Toasty
    exibeToasty(msg, type) {
      this.$toast.open({
        message: msg,
        type: type, // error, success, info

        duration: 5000,
        dismissible: true,
        position: "bottom-right",
      });
    },
    // Toast BoostrapVue
    makeToast(variant = null, msg) {
      this.$bvToast.toast(msg, {
        title: `Mensagem do Sistema`,
        variant: variant, // classes do bootstrap normal: primary,success,danger, etc
        solid: true,
        autoHideDelay: 12000,
      });
    },

    // Ranking
    async getFastUsuarioRanking() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_ranking/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            switch (obj[0].status) {
              case "ok":
                //console.log("ok", this.fastUsuarioRanking);
                break;
              case "ranking_parametro_nao_configurado":
                //console.log(
                //"ranking_parametro_nao_configurado",
                //this.fastUsuarioRanking
                //);
                break;
              case "ranking_sem_pontos":
                //console.log("ranking_sem_pontos", this.fastUsuarioRanking);
                break;
              case "ranking_pontos_insuficiente":
                //console.log(
                //"ranking_pontos_insuficiente",
                //this.fastUsuarioRanking
                //);
                break;
              case "ranking_pontos_superior":
                //console.log("ranking_pontos_superior", this.fastUsuarioRanking);
                break;
              case "ranking_nao_identificado":
                //console.log("ranking_nao_identificado", this.fastUsuarioRanking);
                break;
              case "ranking_nao_configurado":
                //console.log("ranking_nao_configurado", this.fastUsuarioRanking);
                break;
              default:
              //console.log("nenhum_status", this.fastUsuarioRanking);
            }

            resolve(obj[0]);
          } else {
            resolve(obj);
          }
        } catch (e) {
          reject(e);
        }
      });
    },

    // Verificação para o aluno
    async getDependenciasVisaoAluno(id_plataforma) {
      return new Promise(async (resolve, reject) => {
        // Busca informações da plataforma
        this.getPlataformaData(id_plataforma)
          .then(() => {
            if (this.$store.state.fastPlataforma.status == "C") {
              window.location.href = "/minhas-plataformas";
              reject(false);
              return false;
            }
            // Busca permissões da plataforma
            return this.getPlataformaPermissoesData(id_plataforma);
          })
          .then(() => {
            // Usuário não é ativo? volta para minhas plataformas
            if (this.$store.state.fastPermissoes.ativo != "S") {
              window.location.href = "/minhas-plataformas";
              reject(false);
              return false;
            }
          })
          .then(() => {
            // Busca ranking do usuário
            return this.getFastUsuarioRanking();
          })
          .then((e) => {
            // Armazena ranking
            if (this.fastUsuarioRanking) this.fastUsuarioRanking = e;

            // Busca personalização da plataforma
            return this.getFastPersonalizacao(id_plataforma);
          })
          .then((e) => {
            // Se foi personalizado aplica cor nos textos
            if (this.$store.state.fastPersonalizacao.aluno_font_color) {
              Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
            }
            // Se foi personalizado aplica cor nos textos dos menus
            if (this.$store.state.fastPersonalizacao.menu_font_color) {
              Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
            }
          })
          .then(() => {
            // Busca módulos da plataforma
            return this.getFastPlataformaModulos(id_plataforma);
          })
          .then(() => resolve(true))
          .catch((e) => {
            reject(e);
          });
      });
    },

    async getFastDependecias(id_plataforma, perfil){
      return new Promise(async (resolve, reject) => {
        // Busca informações da plataforma
        this.getPlataformaData(id_plataforma)
          .then(() => {
            if (this.$store.state.fastPlataforma.status == "C") {
              window.location.href = "/minhas-plataformas";
              reject(false);
              return false;
            }
            // Busca permissões da plataforma
            return this.getPlataformaPermissoesData(id_plataforma);
          })
          .then(() => {
            switch (perfil) {
              case 'administrador':
                if (this.$store.state.fastPermissoes.ativo != "S" || this.$store.state.fastPermissoes.administrador != "S") {
                  window.location.href = "/minhas-plataformas";
                  reject(false);
                  return false;
                }
                break;
              case 'professor':
                if (this.$store.state.fastPermissoes.ativo != "S" || (this.$store.state.fastPermissoes.administrador != "S" && this.$store.state.fastPermissoes.professor != "S")) {
                  window.location.href = "/minhas-plataformas";
                  reject(false);
                  return false;
                }
                break;
              case 'aluno':
                if (this.$store.state.fastPermissoes.ativo != "S" || (this.$store.state.fastPermissoes.administrador != "S" && this.$store.state.fastPermissoes.professor != "S" && this.$store.state.fastPermissoes.aluno != "S" && this.$store.state.fastPermissoes.secretario != "S" && this.$store.state.fastPermissoes.tesouraria != "S")) {
                  window.location.href = "/minhas-plataformas";
                  reject(false);
                  return false;
                } else {
                  // Busca ranking
                  this.getFastUsuarioRanking().then(e => this.fastUsuarioRanking = e)
                }
                break;
              case 'secretario':
                if (this.$store.state.fastPermissoes.ativo != "S" || (this.$store.state.fastPermissoes.administrador != "S" && this.$store.state.fastPermissoes.secretario != "S")) {
                  window.location.href = "/minhas-plataformas";
                  reject(false);
                  return false;
                }
                break;
              case 'tesouraria':
                if (this.$store.state.fastPermissoes.ativo != "S" || (this.$store.state.fastPermissoes.administrador != "S" && this.$store.state.fastPermissoes.tesouraria != "S")) {
                  window.location.href = "/minhas-plataformas";
                  reject(false);
                  return false;
                }
                break;
              case 'responsavel':
                if (this.$store.state.fastPermissoes.ativo != "S" || (this.$store.state.fastPermissoes.administrador != "S" && this.$store.state.fastPermissoes.responsavel != "S")) {
                  window.location.href = "/minhas-plataformas";
                  reject(false);
                  return false;
                }
                break;
              default:
                window.location.href = "/minhas-plataformas";
                reject(false);
                return false;
                break;
            }            
            
          })
          .then((e) => {
            // Busca personalização da plataforma
            return this.getFastPersonalizacao(id_plataforma);
          })
          .then((e) => {
            // Se foi personalizado aplica cor nos textos
            if (this.$store.state.fastPersonalizacao.aluno_font_color) {
              Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
            }
            // Se foi personalizado aplica cor nos textos dos menus
            if (this.$store.state.fastPersonalizacao.menu_font_color) {
              Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
            }
          })
          .then(() => {
            // Busca módulos da plataforma
            return this.getFastPlataformaModulos(id_plataforma);
          })
          .then(() => resolve(true))
          .catch((e) => {
            reject(e);
          });
      });
    },

    // Busca módulos da plataforma
    async getFastPlataformaModulos(id_plataforma, refresh) {
      return new Promise(async (resolve, reject) => {
        // Se não tiver na sessão, ou forçar refresh for true, ou a plataforma da sessão é diferente da passada por parâmetro
        if (!this.getFastSessao(settings.fastSessaoPlataformaModulos) || refresh || this.getFastSessao(settings.fastSessaoPlataformaModulos).id_plataforma != id_plataforma) {
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_parametro/lista_modulos?id_plataforma=" + id_plataforma, this.fastAjaxOptions("GET"));
            let json = await resp.json();
            let obj = Array.from(json);
            if (obj.length) {
              this.$store.state.fastPlataformaModulos = obj[0];
              this.setFastSessao(settings.fastSessaoPlataformaModulos, this.$store.state.fastPlataformaModulos);
            }
            resolve(true);
          } catch (e) {
            console.log("Erro", e);
            reject(e);
          }
        } else {
          this.$store.state.fastPlataformaModulos = this.getFastSessao(settings.fastSessaoPlataformaModulos);
          resolve(true);
        }
      });
    },
    // Ordena array de objetos passando propriedade
    reornedarArrayObjetos(key, order = "asc") {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }
        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    },
    // Método genérico de inserção
    async promiseInserirFastApi(obj, controller) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/" + controller + "/insere",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Método genérico de atualização
    async promiseAtualizarFastApi(obj, controller) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/" + controller + "/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Método genérico de exclusão
    async promiseExcluirFastApi(obj, controller) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/" + controller + "/exclui",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Método genérico de GET
    async promiseGetFastApi(controller, param) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
            controller + "?" + param,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          resolve(json);

        } catch (e) {
          reject(e);
        }
      });
    },
    async promiseGetFastApiHtml(controller, param) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
            controller + "?" + param,
            this.fastAjaxOptionsHtml("GET")
          );
          resolve(resp.text());

        } catch (e) {
          reject(e);
        }
      });
    },
    // Método genérico de POST
    async promisePostFastApi(obj, rota) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + rota,
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    base64encode(string) {
      return btoa(string)
    },
    base64decode(base64) {
      return atob(base64)
    },
    // Adiciona dias
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    formataPreco(valor) {
      return parseFloat(valor).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formataDataT(data) {
      let dataFormatada = "Sem data"
      if (data) {
        if (data.split("T").length) dataFormatada = data.split("T")[0].split("-")[2] + "/" + data.split("T")[0].split("-")[1] + "/" + data.split("T")[0].split("-")[0]
        else
        dataFormatada = data
      }
      return dataFormatada
    },
    formataDataHoraT(data) {
      let dataFormatada = "Sem data"
      if (data) {
        if (data.split("T").length) {
          dataFormatada = data.split("T")[0].split("-")[2] + "/" + data.split("T")[0].split("-")[1] + "/" + data.split("T")[0].split("-")[0]       
          if (data.split("T")[1].split(".").length) dataFormatada += " às " + data.split("T")[1].split(".")[0]
          else
          dataFormatada += " às " + data.split("T")[1]
        }
        else {
          dataFormatada = data
        }
        
      }

      return dataFormatada
    },
    //busca cep api correio
    async buscaCEPCorreio(cep) {
      let objCep = {
        cep: "",
        logradouro: "",
        bairro: "",
        localidade: "",
        uf: ""
      }
      return new Promise(async (resolve, reject) => {
        if (cep.length == 9) {
          fetch(`https://viacep.com.br/ws/${cep.replace("-", "")}/json/`)
            .then((e) => {
              return e.json();
            })
            .then((data) => {
              objCep.cep = data.cep;
              objCep.logradouro = data.logradouro;
              objCep.bairro = data.bairro;
              objCep.cidade = data.localidade;
              objCep.uf = data.uf;
              resolve(objCep)
            })
            .catch((error) => {
              reject("CEP não encontrado")
            });
        } else {
          resolve(objCep)
        }
      });
    },
    removeCasasDecimais(num, fixed) {
      fixed = fixed || 0;
      fixed = Math.pow(10, fixed);
      return Math.floor(num * fixed) / fixed;
    },
    dataFormatada(data){
      let dia = ""
      let mes = ""
      let ano = ""
      if (data) {
        dia  = data.getDate().toString().padStart(2, '0')
        mes  = (data.getMonth()+1).toString().padStart(2, '0') //+1 pois no getMonth Janeiro começa com zero.
        ano  = data.getFullYear()
      }      
      return dia+"/"+mes+"/"+ano;
    },
    // Médodo para nagevegação 
    alteraFastNavegacao(pagina) {
        let fastNavegacao = Object.assign({}, this.fastNavegacao);
        const keys = Object.keys(fastNavegacao)
        keys.forEach(k => this.fastNavegacao[k] = false)    
        this.fastNavegacao[pagina] = true
    },
    // Valida email
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    // Restrições e informações extra do email
    validaOpcoesEmail(obj) {
      switch (obj.nome_email) {
        case "Administrador aceitou o convite":
          obj.descricao = "Esse email será disparado para o destino configurado quando um usuário administrador confirmar o convite na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Professor aceitou o convite":
          obj.descricao = "Esse email será disparado para o destino configurado quando um usuário professor confirmar o convite na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Aluno aceitou o convite":
          obj.descricao = "Esse email será disparado para o destino configurado quando um aluno confirmar o convite na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Secretário aceitou o convite":
          obj.descricao = "Esse email será disparado para o destino configurado quando um secretário confirmar o convite na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Tesoureiro aceitou o convite":
          obj.descricao = "Esse email será disparado para o destino configurado quando um tesoureiro confirmar o convite na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Responsável aceitou o convite":
          obj.descricao = "Esse email será disparado para o destino configurado quando um responsável confirmar o convite na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Aluno iniciou um curso":
          obj.descricao = "Esse email será disparado para o destino configurado quando um aluno iniciar ou reiniciar qualquer curso na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma,%nome_curso";
          break;
        case "Aluno finalizou um curso":
          obj.descricao = "Esse email será disparado para o destino configurado quando um aluno finalizar qualquer curso na plataforma.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma,%nome_curso";
          break;
        case "Usuário foi desativado":
          obj.descricao = "Esse email será disparado para o usuário que tiver sua conta desativada pelo painel de administração de usuários da plataforma. O destino não é configurável.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma";
          break;
        case "Novo curso disponível na plataforma":
          obj.descricao = "Esse email será disparado para o aluno quando for adicionado a um curso restrito na plataforma. O destino não é configurável.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma,%nome_curso";
          break;
        case "Nova publicação no mural":
          obj.descricao = "Esse email será disparado para todos os usuários da plataforma quando uma nova publicação do mural for criada e possuir o email ativo. O destino não é configurável.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma";
          break;
        case "Convite para aluno":
          obj.descricao = "Esse email será recebido pelo aluno que for convidado pelo painel de administração de usuários da plataforma. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma,%link_confirmar";
          break;
        case "Convite para professor":
          obj.descricao = "Esse email será recebido pelo professor que for convidado pelo painel de administração de usuários da plataforma. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma,%link_confirmar";
          break;
        case "Convite para administrador":
          obj.descricao = "Esse email será recebido pelo administrador que for convidado pelo painel de administração de usuários da plataforma. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma,%link_confirmar";
          break;
        case "Convite para secretaria":
          obj.descricao = "Esse email será recebido pelo usuário que for convidado pelo painel de administração de usuários da plataforma para administrar a secretaria. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma,%link_confirmar";
          break;
        case "Convite para tesouraria":
          obj.descricao = "Esse email será recebido pelo usuário que for convidado pelo painel de administração de usuários da plataforma para administrar o modo financeiro. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma,%link_confirmar";
          break;
        case "Convite para responsável":
          obj.descricao = "Esse email será recebido pelo usuário que for convidado pelo painel de administração de usuários da plataforma como responsável de um aluno. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_plataforma,%link_plataforma,%link_confirmar";
          break;
        case "Convite para live":
          obj.descricao = "Esse email será recebido pelo usuário que for convidado para participar de uma live agendada na plataforma. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_plataforma,%data_live,%link_live";
          break;          
        case "Redefinição de senha":
          obj.descricao = "Esse email será recebido pelo usuário que requisitar a troca de senha. O destino não é configurável e não é possível desabilitar esse email. Essa personalização só é efetiva quando a plataforma possui um site com domínio personalizado.";
          obj.obrigatorio = true;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%link_redefinir_senha";
          break;
        case "Aluno finalizou avaliação":
          obj.descricao = "Esse email será disparado para o destino configurado quando um aluno finalizar uma avaliação. A variável %resultado_avaliacao contém o resultado em detalhes da avaliação realizada.";
          obj.obrigatorio = false;
          obj.modulo = "F";
          obj.variaveis_disponiveis = "%nome_usuario,%data_finalizou,%nome_curso,%titulo_aula,%titulo_atividade,%resultado_avaliacao";
          break;
        case "Contrato de matrícula":
          obj.descricao = "Esse email será recebido pelo aluno no momento da geração da matrícula pelo módulo de secretaria, contendo o link para o contrato. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%link_contrato";
          break;
        case "Requerimento solicitado":
          obj.descricao = "Esse email será recebido pelos responsáveis aluno no momento da solicitação de um requerimento pelo módulo de secretaria. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%detalhes_requerimento";
          break;
        case "Ocorrência registrada":
          obj.descricao = "Esse email será recebido pelos responsáveis aluno no momento do registro de uma ocorrência pelo módulo de secretaria. O destino não é configurável e não é possível desabilitar esse email.";
          obj.obrigatorio = true;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%detalhes_ocorrencia";
          break;
        case "Avaliação disponível para aluno":
          obj.descricao = "Esse email será disparado para o aluno quando uma avaliação estiver disponível. O destino não é configurável.";
          obj.obrigatorio = false;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma,%nome_uc,%link_uc";
          break;
        case "Recuperação disponível para aluno":
          obj.descricao = "Esse email será disparado para o aluno quando uma recuperação estiver disponível. O destino não é configurável.";
          obj.obrigatorio = false;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma,%nome_uc,%link_uc";
          break;
        case "Aluno foi aprovado na unidade curricular":
          obj.descricao = "Esse email será disparado para o aluno quando for aprovado em uma unidade curricular. O destino não é configurável.";
          obj.obrigatorio = false;
          obj.modulo = "S";
          obj.variaveis_disponiveis = "%nome_usuario,%nome_plataforma,%link_plataforma,%nome_uc,%data_finalizou,%resultado_avaliacao";
          break;
        default:
          break;
      }
    },
    // Refresh token
    async getRefreshToken() {
      return new Promise(async (resolve, reject) => {
        let options = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          method: "POST",
          body: `grant_type=refresh_token&refresh_token=${this.retornaRefreshToken()}`,
        }
        try {
          let resp = await fetch(settings.endApiFastEad + "api/token", options);
          let res = await resp.json();
          if (res.access_token) {
            this.setFastSessao(settings.fastSessaoToken, res)  
            resolve(true)        
          }
        } catch(e){
          reject(e)
        }        
      });      
    },
    // Loading padrão para dados
    fastLoadingDados(message = 'Carregando...'){
      return `${message}`;
    },
    // Pegar hora no servidor
    async getFastHoraServidor() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/lista_hora", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          //let obj = JSON.parse(json);
          this.$store.state.fastDataAtualServidor = new Date(json.dataAtual)
          console.log(json)
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },    
    arredondaCasasDecimais(num) {
      let valor = parseFloat(num)
      if (valor.toString().indexOf(".") != "-1") {
        let valor1 = valor.toString().split(".")[0]
        let valor2 = valor.toString().split(".")[1]
        if (valor2.length > 2) {
          valor2 = parseInt(valor2.substring(0, 2)) + 1
        }
        valor = parseFloat(valor1.toString() + "." + valor2.toString())
      }
      return valor
    },
    validaTelefoneFormatado(telefone){
      return /(\(\d{2}\)\s)(\d{4,5}\-\d{4})/.test(telefone)
    },
    validaTelefone(telefone){
      telefone = telefone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
      return /(^[1-9]{2}9?[0-9]{8}$)/.test(telefone)
    },
    validaCpfCnpj(val) {
      if (val.length == 14) {
          var cpf = val.trim();
       
          cpf = cpf.replace(/\./g, '');
          cpf = cpf.replace('-', '');
          cpf = cpf.split('');
          
          var v1 = 0;
          var v2 = 0;
          var aux = false;
          
          for (var i = 1; cpf.length > i; i++) {
              if (cpf[i - 1] != cpf[i]) {
                  aux = true;   
              }
          } 
          
          if (aux == false) {
              return false; 
          } 
          
          for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
              v1 += cpf[i] * p; 
          } 
          
          v1 = ((v1 * 10) % 11);
          
          if (v1 == 10) {
              v1 = 0; 
          }
          
          if (v1 != cpf[9]) {
              return false; 
          } 
          
          for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
              v2 += cpf[i] * p; 
          } 
          
          v2 = ((v2 * 10) % 11);
          
          if (v2 == 10) {
              v2 = 0; 
          }
          
          if (v2 != cpf[10]) {
              return false; 
          } else {   
              return true; 
          }
      } else if (val.length == 18) {
          var cnpj = val.trim();
          
          cnpj = cnpj.replace(/\./g, '');
          cnpj = cnpj.replace('-', '');
          cnpj = cnpj.replace('/', ''); 
          cnpj = cnpj.split(''); 
          
          var v1 = 0;
          var v2 = 0;
          var aux = false;
          
          for (var i = 1; cnpj.length > i; i++) { 
              if (cnpj[i - 1] != cnpj[i]) {  
                  aux = true;   
              } 
          } 
          
          if (aux == false) {  
              return false; 
          }
          
          for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
              if (p1 >= 2) {  
                  v1 += cnpj[i] * p1;  
              } else {  
                  v1 += cnpj[i] * p2;  
              } 
          } 
          
          v1 = (v1 % 11);
          
          if (v1 < 2) { 
              v1 = 0; 
          } else { 
              v1 = (11 - v1); 
          } 
          
          if (v1 != cnpj[12]) {  
              return false; 
          } 
          
          for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
              if (p1 >= 2) {  
                  v2 += cnpj[i] * p1;  
              } else {   
                  v2 += cnpj[i] * p2; 
              } 
          }
          
          v2 = (v2 % 11); 
          
          if (v2 < 2) {  
              v2 = 0;
          } else { 
              v2 = (11 - v2); 
          } 
          
          if (v2 != cnpj[13]) {   
              return false; 
          } else {  
              return true; 
          }
      } else {
          return false;
      }
    },
    safeJsonParse(str) {
      try {
          return [null, JSON.parse(str)];
      } catch (err) {
          return [err];
      }
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    // Médodo para limpar propriedades de um objeto 
    limpaPropsObj(obj) {
      //let fastObj = Object.assign({}, obj);
      const keys = Object.keys(obj)
      try {
        keys.forEach(k => {
          switch (typeof obj[k]) {
            case "object":
              // Só limpa se for array
              if (Array.isArray(obj[k])) {
                obj[k] = []            
              } else if (obj[k]){
                this.limpaPropsObj(obj[k])
              }
              break;
            case "string":
              obj[k] = ""
              break;
            case "number":
                obj[k] = 0
                break;
            case "boolean":
                obj[k] = false
                break;
            default:
              break;
          }
        })    
      } catch(e) {
        console.error(e)
      }
      
    },
    // Converte url com imagem para base64
    fastUrlImageToBase64(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
              callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function () {
        callback(false);
      }
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
  },
  // Correção de link do concurseiro
  corrigeLinkConcurseiro(url){
    if (url) {
      if (url.indexOf('www.concurseiro.com') != '-1') {
        url = url.replace('www', 'toussaint')
      }
      else if (url.indexOf('concurseiro.com') != '-1') {
        url = url.replace('concurseiro.com', 'toussaint.concurseiro.com')
      } else {
        url = this.ajustaLinkApiAntiga(url)
      }
    }   
    return url
  },
  // Validação de layouts 
  getLayoutsAluno() {
    return new Promise((resolve, reject) => {
      if (!this.getFastSessao("fastSessaoLayoutAlunoUsuario")){
        this.promiseGetFastApi("api/fast_layout_aluno_usuario/lista", "")
        .then(res => {
          console.log('getLayoutsAluno', res[0]);
          this.setFastSessao("fastSessaoLayoutAlunoUsuario", res[0])
          resolve();
        })
        .catch(e => {
          console.log(e);
          reject(e);
        });
      } else resolve();
    });
  },
  verificaLayoutAluno(){

    const componentesPersonalizados = ["HomeInternoAluno", "HomeInternoAlunoAtendimento", "HomeInternoAlunoMural", "HomeInternoAlunoTurmas", "HomeInternoAlunoTurma", "HomeInternoAlunoTurmaPrograma","HomeInternoAlunoMeusRequerimentos", "HomeInternoAlunoOcorrencias",  "HomeInternoAlunoCursoDetalhes", "HomeInternoAlunoTutoriais", "HomeInternoAlunoCursoAula", "HomeInternoAlunoTurmaProgramaAvaliacao", "HomeInternoAlunoMeusCadernos", "HomeInternoAlunoMeusCursos"];

    const layoutUsuario = this.getFastSessao("fastSessaoLayoutAlunoUsuario");
    
    if (layoutUsuario && layoutUsuario.id_layout_aluno != 1 && componentesPersonalizados.includes(this.$route.name)) {
      this.paginaAtual = `${layoutUsuario.path}/aluno/${this.$route.name}`;
    }
  },
  alteraLayoutAluno(){
    const idLayoutAluno = this.getFastSessao('fastSessaoLayoutAlunoUsuario').id_layout_aluno == 1 ? 2 : 1;

    this.promisePostFastApi({id_layout_aluno: idLayoutAluno}, "api/fast_layout_aluno_usuario/atualiza").then(res => {
      sessionStorage.removeItem('fastSessaoLayoutAlunoUsuario');
      window.location.reload();
    }).catch(e => {
      console.log(e)
    })
  },
  getLayoutsAlunoLength() {
    return new Promise((resolve, reject) => {
        this.promiseGetFastApi("api/fast_layout_aluno/lista", "")
        .then(res => {
          this.$store.state.fastQntdLayouts = res.length
          resolve()
        })
        .catch(e => {
          console.log(e);
          reject(e);
        });
    });
  },
  async getPlataformaDadosPaises() {
    return new Promise(async (resolve, reject) => {
      this.promiseGetFastApi("api/pais_codigo/lista_todos", "")
      .then(obj => {
        if (obj) {
          this.$store.state.fastPlataformaDadosPaises = obj
          resolve(obj)
        }
      })
      .catch(e => {
        console.log(e)
        reject(e);
      })
    })
  },
  getFlag (id_pais) {
    try {
      if (id_pais) {
        const pais = [...this.$store.state.fastPlataformaDadosPaises].find(pais => pais.id_pais === id_pais) 
          return pais.img ? pais.img : null;
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  getFlagByDDI (ddi) {
    try {
      if (ddi) {
        const pais = [...this.$store.state.fastPlataformaDadosPaises].find(pais => pais.fone === ddi) 
          return pais.img ? pais.img : null;
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
},};